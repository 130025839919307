import React from 'react';
import { Link } from 'gatsby';
import * as styles from './footer.module.scss';
import Icon from '../icon/icon';

interface Props {
  siteTitle: string;
}

const Footer = ({ siteTitle }: Props) => {
  return (
    <footer className={`${styles.footer} container-fluid`}>
      <div className={`${styles.content} centered`}>
        {/* <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>{siteTitle}</h4>
              <ul className={styles.footerLinks}>
                <li className="separator">
                  <Link to="/contact">Kapcsolat</Link>
                </li>
                <li>
                  <Link to="/about">Rólunk</Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className={styles.social}>
          {/* Use default <a> tags because these are not Gatsby links */}
          <a href="https://www.facebook.com/marabutor" title="Facebook" target="_blank" rel="noopener" aria-label="Facebook">
            <Icon name="facebook" size={24} />
          </a>
          <a href="mailto:marabutor2@gmail.com" title="E-mail" aria-label="E-mail">
            <Icon name="mail" size={24} />
          </a>
        </div>
        <div className={styles.logoWrapper}>
          <Link to="/" className={styles.logo}>
            <img src="/images/logo.svg" alt="Logo" />
          </Link>
        </div>
        <div className={styles.copyright}>© {new Date().getFullYear()} {siteTitle}</div>
      </div>
    </footer>
  );
};

export default Footer;