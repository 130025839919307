// extracted by mini-css-extract-plugin
export var column = "footer-module--column--Abiyk";
export var columns = "footer-module--columns--m3DN5";
export var content = "footer-module--content--kHUvg";
export var copyright = "footer-module--copyright--Bg9Rz";
export var footer = "footer-module--footer--lU7l1";
export var footerLinks = "footer-module--footer-links--yuOVT";
export var logo = "footer-module--logo--5nyW8";
export var logoWrapper = "footer-module--logo-wrapper--jG-Cd";
export var section = "footer-module--section--W+-UF";
export var sectionTitle = "footer-module--section-title--zpZR2";
export var social = "footer-module--social--AXYhi";