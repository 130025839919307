import React from 'react';
import { graphql } from 'gatsby';
import { Link, Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import * as styles from './header.module.scss';

const Header = (props: any) => {
  const { originalPath, language } = useI18next();
  const { t } = useTranslation();

  console.log('asd', language);

  return (
    <header className={`${styles.header} container-fluid`}>
      <div className="centered">
        <nav className={styles.nav}>
          <div className={styles.navLeft}>
            <Link to="/" className={styles.logo}>
              <img src="/images/logo.svg" alt="Mara logo" />
            </Link>
            <ul className={styles.menu}>
              <li>
                <Link to="/gallery" className={styles.link} activeClassName="active"><Trans>gallery</Trans></Link>
              </li>
              <li>
                <Link to="/contact" className={styles.link} activeClassName="active"><Trans>contact</Trans></Link>
              </li>
            </ul>
          </div>
          <div className={styles.navRight}>
            <Dropdown className={styles.languageDropdown}>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                {language === 'hu' ?
                  <img className={styles.icon} src="/images/hu.svg" alt={t('hungarian')} /> :
                  <img className={styles.icon} src="/images/us.svg" alt={t('english')} />
                }
                <Trans>language</Trans>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link className="dropdown-item" to={originalPath} language="hu">
                  <img className={styles.icon} src="/images/hu.svg" alt={t('hungarian')} />
                  <span><Trans>hungarian</Trans></span>
                </Link>
                <Link className="dropdown-item" to={originalPath} language="en">
                  <img className={styles.icon} src="/images/us.svg" alt={t('english')} />
                  <span><Trans>english</Trans></span>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
      </div>
    </header>
  )
};

export default Header;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;