import React, { FC } from 'react';
import Footer from './footer/footer';
import Header from './header/header';

const Layout: FC<any> = ({ children, page }) => {
  return (
    <div className={`page ${page && page + '-page'}`}>
      <Header />
      <main className="main">{children}</main>
      <Footer siteTitle="Marabútor" />
    </div>
  )
};

export default Layout;