// extracted by mini-css-extract-plugin
export var header = "header-module--header--4dQNe";
export var icon = "header-module--icon--S7vcO";
export var languageDropdown = "header-module--language-dropdown--bmlnK";
export var link = "header-module--link--+sNv4";
export var linkText = "header-module--link-text--jOx82";
export var logo = "header-module--logo--WAoX3";
export var menu = "header-module--menu--hMDNj";
export var nav = "header-module--nav--OD9yD";
export var navLeft = "header-module--nav-left--aaVb7";
export var navRight = "header-module--nav-right--1xRCf";